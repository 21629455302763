<template>
  <div class="font-source px-7">
    <div v-if="getting" class="flex justify-center items-center h-[30vh]">
      <spinner color="ansGreen" class="w-18 h-18" />
    </div>

    <div
      v-else-if="!getting && !paymentFound"
      class="w-full flex flex-col justify-center items-center h-[50vh]"
    >
      <img
        src="@/assets/images/broken_link.svg"
        alt="icon"
        class="w-20 h-20 transform rotate-180"
      />

      <p class="text-red-400 font-bold mt-2">Payment not completed</p>
    </div>

    <!-- Payment verification -->
    <div v-else>
      <!-- STEP 1 -->
      <section v-if="step == 1">
        <!-- For monnify and unified -->
        <div v-if="channel == 'Monnify' || channel == 'UnifiedPay'">
          <div class="my-4">
            <label for="paymentReference">
              Enter Payment Reference to verify payment
            </label>
            <input
              type="text"
              class="input"
              id="paymentReference"
              v-model="form.paymentReference"
            />
          </div>

          <div class="mt-7 mb-4">
            <button
              class="bg-ansGreen text-white px-10 py-2.5 w-full rounded"
              :disabled="!form.paymentReference"
              @click="verifyMonnify"
            >
              Verify Payment
            </button>
          </div>
        </div>

        <!-- For Bank tranfer -->
        <div v-if="channel == 'Appmart Virtual Account'">
          <div class="my-4">
            <label for="bankName">
              Select the bank the money was paid from
            </label>
            <v-select
              id="bankName"
              label="bankName"
              :reduce="(b) => b.bankName"
              :options="banks"
              v-model="form.bankName"
              placeholder="-- select source bank --"
            ></v-select>
          </div>

          <div class="my-4">
            <label for="accountNumber"> Enter the source account number </label>
            <input
              type="text"
              class="input"
              id="accountNumber"
              placeholder="2028874574"
              v-model="form.accountNumber"
            />
          </div>

          <div class="mt-7">
            <button
              class="bg-ansGreen text-white px-10 py-2.5 w-full rounded"
              :disabled="!bankReady"
              @click="verifyBankTransfer"
            >
              Verify Payment
            </button>
          </div>
        </div>

        <!-- <p>{{ transactionData }}</p> -->
        <!-- <br />
        <p>{{ validInvoice }}</p> -->
      </section>

      <!-- STEP 2 -->
      <section v-if="step == 2">
        <PersonalInfo @done="generateReceipt" />
      </section>
    </div>
  </div>
</template>

<script>
import decryptor from "@/services/decryptor.js";
import PersonalInfo from "@/components/track/PersonalInfo.vue";

export default {
  name: "GenerateReceipt",

  components: {
    PersonalInfo,
  },

  props: {
    invoiceNumber: {
      type: String,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      step: 1,
      paymentFound: false,
      getting: true,
      transactionData: null,
      validInvoice: null,
      banks: [],
      form: {
        accountNumber: null,
        bankName: null,
        paymentReference: null,
      },
    };
  },

  async created() {
    await this.fetchPaymentData();
    await this.fetchBankData();

    this.$store.commit("general/wipeReceipt");
  },

  mounted() {
    const step = this.$route.query.st;
    if (step && step != 0) {
      this.$store.commit("general/updateStep", Number(step));
    }
  },

  computed: {
    channel() {
      return this.transactionData.paymentChannel;
    },

    bankReady() {
      return !!this.form.bankName && this.form.accountNumber;
    },
  },

  methods: {
    async fetchPaymentData() {
      try {
        this.getting = true;

        const res = await this.$http.get(
          "/invoice/verify/" + this.invoiceNumber,
          {
            headers: { noauth: true },
          }
        );
        this.getting = false;

        if (!res) {
          this.paymentFound = false;
          this.$emit("hideForm");
          return;
        }

        const encryptedData = res.data.data;

        const decryptedData = decryptor(encryptedData);
        if (!decryptedData) {
          this.paymentFound = false;
          return;
        }

        this.transactionData = decryptedData.transactionData;
        this.validInvoice = decryptedData.validInvoice;
        this.paymentFound = true;
      } catch (error) {
        this.getting = false;
        console.log(error);
      }
    },

    async fetchBankData() {
      try {
        const res = await this.$http.get("/bank?limit=400", {
          headers: { noauth: true },
        });

        if (!res) {
          this.banks = [];
          return;
        }

        this.banks = res.data.data.data;
      } catch (error) {
        this.getting = false;
        console.log(error);
      }
    },

    async generateReceipt(d) {
      try {
        this.getting = true;

        const res = await this.$http.post(
          "/receipt",
          {
            invoiceNumber: this.invoiceNumber,
            taxPayerId: d.nin,
          },
          {
            headers: { noauth: true },
          }
        );
        this.getting = false;

        if (!res) {
          return;
        }

        this.$emit("generatedone");
      } catch (error) {
        this.getting = false;
        console.log(error);
      }
    },

    verifyMonnify() {
      if (
        this.form.paymentReference.toUpperCase() !=
        this.transactionData.paymentTransactionReference.toUpperCase()
      ) {
        this.$swal({
          icon: "error",
          text: "Payment reference provided is incorrect",
        });
        return;
      }
      this.verifyTaxpayer();
    },

    verifyBankTransfer() {
      if (
        this.form.accountNumber != this.transactionData.sourceBankAccountNumber
      ) {
        this.$swal({
          icon: "error",
          text: "Payment data provided is incorect",
        });
        return;
      }
      this.verifyTaxpayer();
    },

    verifyTaxpayer() {
      if (this.transactionData.taxId) {
        this.generateReceipt({ nin: this.transactionData.taxId });
        return;
      }

      this.step += 1;
    },
  },
};
</script>

<style scoped></style>
